import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BatchCTA from "../batchCta"
import BenefitsTextCarousel from "../benefitsTextCarousel"

const HomeHero = () => {
    const data = useStaticQuery(graphql`
      query {
        file: file(relativePath: { eq: "youtube-screenshot.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
        partner: file(relativePath: { eq: "partner-logo.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
        },
        plus: file(relativePath: { eq: "plus-logo.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const [isVideoVisible, displayVideo] = useState(false);
    const renderStarSVG = () => {
        return(
            <>
            <svg className="icon" aria-label={'star'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#fdd991">
                    <path d="M13,16c-0.2,0-0.4-0.1-0.6-0.2L8,12.5l-4.4,3.3c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.3-1.1l1.9-5.2L0.4,6.8 C0.1,6.5-0.1,6.1,0.1,5.7C0.2,5.3,0.6,5,1,5h4.5l1.5-4.3C7.2,0.3,7.6,0,8,0s0.8,0.3,0.9,0.7L10.5,5H15c0.4,0,0.8,0.3,0.9,0.7 c0.1,0.4,0,0.9-0.3,1.1l-3.5,2.6l1.9,5.2c0.1,0.4,0,0.9-0.3,1.1C13.4,15.9,13.2,16,13,16z"/>
                </svg>
            </svg>
            </>
        )
    }

    const renderVideo = () => {
        if (isVideoVisible) {
            return <iframe
              loading={'lazy'}
              title="Batch Intro Video"
              className="overflow-visible rounded-lg"
              width="530"
              height="315"
              src="https://www.youtube.com/embed/kUVOnOkCzg0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}/>
        }
        return <div className="overflow-visible rounded-lg "
                    data-yt-url="https://www.youtube.com/embed/kUVOnOkCzg0"
                    onClick={() => displayVideo(true)}>
                    <Img
                      fluid={data.file.childImageSharp.fluid}
                      alt={'Batch Youtube Tutorial'}
                    />
                </div>
    }

    const renderArrowSVG = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="icon">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg> 
        )
    }

    return(
        <div className="relative overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-0">
                <svg className="absolute top-0 transform translate-x-64 -translate-y-8 left-1/2" width="640" height="784" fill="none" viewBox="0 0 640 784">
                <defs>
                    <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />

                </svg>
            </div>
            <div className="relative pb-10 md:pb-12 lg:pb-14 xl:pb-16">
                <main className="max-w-screen-xl px-4 mx-auto mt-2 sm:mt-8 sm:px-6 md:mt-12 xl:mt-12">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                        <div className="text-sm font-semibold tracking-wide text-gray-500 uppercase sm:text-base lg:text-sm xl:text-base">
                            Get it on the Shopify App Store
                        </div>
                        <h1 className="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:leading-none sm:text-5xl lg:text-5xl xl:text-5xl">
                            <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                                Auction Marketing
                                <br className="inline"/>Automation for Shopify
                            </span>
                        </h1>
                        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                            Batch lets you collect and convert customer offers into revenue. A new and innovative way to <BenefitsTextCarousel inline={true} punctuation={true}/>
                        </p>

                        <p className="mt-3 text-sm text-gray-500 sm:mt-5 sm:text-base md:text-md xl:text-lg">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
                            {renderStarSVG()} 5 out of 5 stars (<a target="_blank" href={'https://apps.shopify.com/batch-offers#reviews'} className={'underline batch-peach'}>See all reviews</a>)
                            </span>
                        </p>
                        
                        <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                            <p className="text-base font-medium text-gray-600">
                                Collect unlimited offers on the free plan 
                            </p>
                            <div className="mt-3">
                                <BatchCTA content="Create Free Account" px="8" py="5" fontSize="xl" />
                            </div>

                            <div className="mt-5 text-xs tracking-wide text-gray-400 uppercase font-base sm:text-sm lg:text-xs xl:text-sm"> 
                            {renderArrowSVG()} Free Install {renderArrowSVG()} Automatic Setup {renderArrowSVG()} 10x ROI Guaranteed
                            </div>

                            <div className="flex flex-row items-center mt-2">
                                <div>
                                    <Img fluid={data.partner.childImageSharp.fluid} alt="Batch is a Shopify Partner" className={"h-auto w-52"} />
                                </div>
                                <div>
                                    <Img fluid={data.plus.childImageSharp.fluid} alt="Batch works perfectly with Shopify Plus" className={"h-auto w-48"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                    <svg className="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784">
                        <defs>
                        <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
                        <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
                    </svg>
                    <div className="relative w-full mx-auto shadow-lg lg:max-w-md">
                        {renderVideo()}
                    </div>
                    </div>
                </div>
                </main>
            </div>
        </div>
    )
}

export default HomeHero