import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HomeFeatures = () => {
    const data = useStaticQuery(graphql`
      query {
        hybridCheckout: file(relativePath: { eq: "hybrid-checkout.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
        consumerOffer: file(relativePath: { eq: "Consumer-Offer.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return(
        <div className="py-16 bg-gray-50 lg:py-24">
            <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
                <svg className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                    <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                <div className="relative">
                <h3 className="text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                    Grow sales without markdowns
                </h3>
                <p className="max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500">
                    Let customers choose the price they're willing to pay rather than blasting your customers with discount codes and compromising margins.
                </p>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="relative">
                    <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    The only checkout of its kind
                    </h4>
                    <p className="mt-3 text-lg leading-7 text-gray-500">
                    Batch appears in your store's most abandonned areas and lets your customers checkout with a mix of items they want to Buy Now and offers on items they would otherwise abandon.
                    </p>

                    <ul className="mt-10">
                    <li>
                        <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Lightning fast checkout</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                            Let customers checkout from anywhere on your website in 3 clicks and less than 20 seconds
                            </p>
                        </div>
                        </div>
                    </li>
                    <li className="mt-10">
                        <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                            </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Collect prepaid offers</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                            Increase cart activity by collecting preauthorized offers on items customers are on the fence about
                            </p>
                        </div>
                        </div>
                    </li>
                    <li className="mt-10">
                        <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Reduce cart abandonment</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                            Never lose a sale because of price again and discover what your customers are really willing to pay
                            </p>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>

                <div className="relative mt-10 -mx-4 lg:mt-0">
                    <svg className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                    <defs>
                        <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                    </svg>
                    <div>
                        <Img
                          fluid={data.hybridCheckout.childImageSharp.fluid}
                          alt={"Batch offers a hybrid checkout mixing 'Buy Now' with prepaid offers."}
                          className={"relative mx-auto"}
                          style={{width: "490px"}}
                        />
                    </div>
                    {/*<img class="relative mx-auto" width="490" src={hybridCheckoutImage} alt="Batch offers a hybrid checkout mixing 'Buy Now' with prepaid offers." />*/}
                </div>
                </div>

                <svg className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                    <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div className="lg:col-start-2">
                    <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                        Turn offers into orders in seconds
                    </h4>
                    <p className="mt-3 text-lg leading-7 text-gray-500">
                        When you accept an offer, Batch immediately captures the payment and transforms the offer into a new unfulfilled order. Instantly Grow sales by accepting as many offers at a time as you like!
                    </p>

                    <ul className="mt-10">
                        <li>
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                </svg>
                            </div>
                            </div>
                            <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Activate offers in seconds</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                When an offer is made, it preauthorizes a transaction that you as a merchant can claim with a single click right from the Shopify Admin
                            </p>
                            </div>
                        </div>
                        </li>
                        <li className="mt-10">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                </svg>
                            </div>
                            </div>
                            <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Create instant store revenue</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                As a merchant you’ll begin receiving several more offers than you do conversions. Cherry pick which offers to accept one by one or whole rows of offers by the batch
                            </p>
                            </div>
                        </div>
                        </li>
                        <li className="mt-10">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            </div>
                            <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">Clear out any inventory in seconds</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                With Batch, you can clear and sell out any inventory. Use Batch aggressively on the off-season and passively during BFCM!
                            </p>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </div>

                    <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
                    <svg className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                        <defs>
                        <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                    </svg>
                    <Img
                      fluid={data.consumerOffer.childImageSharp.fluid}
                      alt={"Batch lets customers make offers on items they don't plan on buying today."}
                      className={"relative mx-auto"}
                      style={{width: "490px"}}
                    />
                    {/*<img className="relative mx-auto" width="490" src={consumerOfferImage} alt="Batch lets customers make offers on items they don't plan on buying today" />*/}
                    </div>
                </div>
                </div>
            </div>
            </div>
    )
}

export default HomeFeatures