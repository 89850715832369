import React from 'react'
import { Link } from 'gatsby'
import BatchCTA from '../batchCta'

const HomeCta = () => {
    return(
        <div className="bg-white">
            <div className="max-w-screen-xl px-4 py-12 mx-auto text-center sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Ready to dive in?
                    <br/>
                    Start collecting offers for free today.
                </h2>
                <div className="flex justify-center mt-8">
                    <BatchCTA px="8" py="6" fontSize="xl" content="Start collecting offers"/>
                    <div className="inline-flex py-2 ml-3">
                        <Link to="/request-demo" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-indigo-700 transition duration-150 ease-in-out bg-indigo-100 border border-transparent rounded-md hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300">
                            Request demo
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeCta