import React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HomeHero from '../components/index/homeHero'
import HomeFeatures from '../components/index/homeFeatures'
import HomeTrusted from '../components/index/homeTrusted'
import HomeCta from '../components/index/homeCta'
import PrimaryTestimonial from '../components/primaryTestimonial'
import HomeMerchantPreview from '../components/index/homeMerchantPreview'

const IndexPage = () => {
  const testimonialPeople = ["alexis", "brie", "jayna", "maegan"]
  const testimonialPerson = testimonialPeople[Math.floor(Math.random()*testimonialPeople.length)]

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `);

  return (
    <div>
      <Helmet>
          {/* Meta */}
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta charSet="utf-8" />
            <meta name="title" content={`${data.site.siteMetadata.title}`}/>
            <meta name="description" content={data.site.siteMetadata.description} /> 
            <link rel="canonical" href={`https://www.sellwithbatch.com/`} />
        </Helmet>
      <Layout>
        <HomeHero />
        <HomeTrusted />
        <PrimaryTestimonial person={testimonialPerson} />
        <HomeFeatures />
        <HomeCta />
        <HomeMerchantPreview />
      </Layout>
    </div>
  )
}

export default IndexPage
