import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HomeMerchantPreview = () => {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "merchant-preview.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    return(
        <div className="-mb-8 md:-mb-4">
            <Img 
                className="relative w-full mx-auto md:w-2/3" 
                alt="Batch lets you turn prepaid customer offers into brand new unfulfilled orders in seconds"
                fluid={data.file.childImageSharp.fluid}
            />
        </div>
    )
}

export default HomeMerchantPreview