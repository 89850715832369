import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HomeTrusted = () => {
  const data = useStaticQuery(graphql`
      query {
        laagam: file(relativePath: { eq: "laagam.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
        nellenby: file(relativePath: { eq: "nellenby-logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
        southernShirt: file(relativePath: { eq: "southern-shirt.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

  return(
    <>
    <div className="mb-5 bg-indigo-100 bg-opacity-25 bg-wiggle">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 className="max-w-md mx-auto text-3xl font-extrabold text-center text-indigo-900 lg:max-w-xl lg:text-left">
            Hundreds of innovative Shopify retailers sell with Batch
          </h2>
          <div className="self-center flow-root mt-8 lg:mt-0">
            <div className="flex flex-wrap justify-between -mt-4 -ml-8 lg:-ml-4">
              <div className="flex justify-center flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <Img
                  fluid={data.laagam.childImageSharp.fluid}
                  alt={'Laagam'}
                  className="w-40 h-14"
                />
              </div>
              <div className="flex justify-center flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <Img
                  fluid={data.nellenby.childImageSharp.fluid}
                  alt={'Nellenby'}
                  className="w-40 h-14"
                />
              </div>
              <div className="flex justify-center flex-grow flex-shrink-0 mt-4 ml-8 lg:flex-grow-0 lg:ml-4">
                <Img
                  fluid={data.southernShirt.childImageSharp.fluid}
                  alt={'Southern Shirt Logo'}
                  className="w-40 h-14"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default HomeTrusted